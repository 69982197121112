import React, { forwardRef } from 'react'
import styled from 'styled-components'
import fingerprint from '../resource/img/01/fingerprint.png'

const ContactX = forwardRef((props,ref)=> {
  return (
    <Container>
      <div className='one-part' ref={ref}>
            <span><b>C</b>ontactez-Nous</span>
            <p>
                  Téléphone : +225 0707009017 / +225 27202375119<br/>
                  Adresse : Abidjan, Plateau<br/>
                  Mail : infos@saraiiko.com
            </p>
            <div>Retrouvez-Nous</div>
            <img src={fingerprint} />
      </div>

      <div className='two-part'>
            <span className='ti'>NewsLetter</span>
            <div className='contact'>
                  <input type={'email'} placeholder={'Entrez votre adresse mail'} />
                  <input type={'text'} placeholder={'Entrez votre nom'} />
                  <input type={'text'} placeholder={'Entrez l\'objet du message'} />
                  <textarea placeholder='Entrez votre message ici ...' cols={16} />
                  <input type={'submit'} value={'Envoyer'} />
            </div>
      </div>

      <div className='three-part'>
            <span className='tag'>©SaasTribe 2020 ¤ Concept Created by Sundhar</span>
            <span className='rs'>.</span>
      </div>
    </Container>
  )
})

const Container =  styled.div`
      position: relative;
      display: grid;
      grid-template-columns: repeat(2,50%);
      grid-template-rows: 1fr /1fr 1fr;
      height: 80vh;
      width: 100%;
      background-color: transparent;
     
      .one-part{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;
            padding: 50px;
            text-align: left;
            background-color: whitesmoke;
            font-weight: bold;
            width: 100%;
            height: 70vh;
            span{
                  font-size: 34px;
                  b{
                        color: orangered;
                        font-size: 46px;
                        font-weight: 300;
                  }
            }
            p{
                  font-size: 16px;
                  text-align: left;
                  font-weight: lighter;
            }
            div{
                  cursor: pointer;
                  width: 200px;
                  color: orange;
                  border: 2px solid orangered;
                  padding: 6px 20px;
                  text-align: left;
                  border-radius: 5px;
                  background-color: transparent;
            }
            img{
                  position: absolute;
                  width: 500px;
                  top: 80px;
                  right: -250px;
                  filter: contrast(5%) blur(1px);
                  animation: linear infinite fade;
            }
            
      }
      .two-part{
            background-color: white;
            width: 100%;
            height: 100%;
            text-align: center;
            .ti{
                  
                  font-size: 24px;
                  color: red;
            }
            .contact{
                  transform: scale(0.8);
                  height: 100%;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  flex-direction: column;
                  input:not([type="submit"]),textarea{
                        width: 300px;
                        outline: none;
                        padding: 5px;
                        padding-inline-start: 20px;
                        box-shadow: 2px 0px 6px rgba(0,0 ,0,0.2);
                        
                  }
                  input[type="submit"]{
                        width: 200px;
                        background-color: orange;
                        padding: 10px;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        border: 1px solid;
                        border-radius: 8px;
                  }

            }
      }
      .three-part{
            background-color: maroon;
            width: 100%;
            color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 100%;
            font-size: 11px;
            grid-column: 1/3;
           
            
      }
      @media screen and (max-width: 700px){
            display: flex;
            flex-direction: column;
            .two-part{
                  padding: 30px 0;
            }
            .three-part{
                  height: 100px;
            }
      }
`;

export default ContactX