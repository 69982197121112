import React, { useState } from 'react'
import styled from 'styled-components';
import logo from '../resource/img/01/saraiiko.png'


const Header = ({servicesRef,whoRef,makeRef,contactRef}) => {
      const [active,setActive] = useState(true);

      const handleClickScroll = (e)=>{
            e.preventDefault();
            servicesRef.current.scrollIntoView({behavior : 'smooth'});
      }
      const handleClickScroll_ = (e)=>{
            e.preventDefault();
            whoRef.current.scrollIntoView({behavior:'smooth'})
      }
      const handleClickScroll__ = (e)=>{
            e.preventDefault();
            makeRef.current.scrollIntoView({behavior:'smooth'})
      }
      const handleClickScroll___ = (e)=>{
            e.preventDefault();
            contactRef.current.scrollIntoView({behavior:'smooth'})
      }

  return (
      <Main>
            <img src={logo} />
            <div>
                  <span href="#home" >Accueil</span>
                  <span href="#who" onClick={handleClickScroll_}>Qui sommes nous</span>
                  <span href="#services" onClick={handleClickScroll}>Nos services</span>
                  <span href="#make" onClick={handleClickScroll__}>Nos Réalisations</span>
                  <span href="#contact" onClick={handleClickScroll___}>Contactez-nous</span>
            </div>
      </Main>
  )
}
const Main = styled.div`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: whitesmoke;
      img{
            height: 80px;
      }
      div{
            width: 800px;
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            
            span{
                  cursor: pointer;
                  &:hover{
                       
                        &::after{
                              transition: all 200ms;
                              content:'Voir' ;
                              border: 1px solid orange;
                              height: 2px;
                              border-radius: 15px;
                              width: 2px;
                              font-size: 10px;
                              margin-left: 8px;
                              font-weight: bold;
                        }
                  opacity: 0.5;
                  }
            }
      }
      @media (max-width: 1000px) {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: whitesmoke;
            div {
                  display: none;
            }
      }     

`;

export default Header;