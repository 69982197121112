import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Slide, Zoom } from 'react-slideshow-image';
import slide from '../resource/img/02/SLIDE1a.jpg'
import slide1 from '../resource/img/02/SLIDE2a.jpg'
import slide2 from '../resource/img/02/SLIDE3a.jpg'
import slide3 from '../resource/img/02/silde1.jpg'
import styled from 'styled-components';
//icones
import icone1 from '../resource/img/01/client.svg'
import icone2 from '../resource/img/01/pen.svg'
import icone3 from '../resource/img/01/search.svg'
import icone4 from '../resource/img/01/talk.svg'
import base from '../resource/img/01/base.svg'



const slideImages = [
      {
        file: slide3
      },

      {
        file:slide2
      },
    
    ];

const BannerSlider = ({templateRef,conRef}) =>{

  const handleClickScroll = (e)=>{
    e.preventDefault();
    templateRef.current.scrollIntoView({behavior : 'smooth'})
  }
  const handleClickScroll_ = (e)=>{
    e.preventDefault();
    conRef.current.scrollIntoView({behavior : 'smooth'})
  }
  return (
      <BannerContainer>
      <Slide transitionDuration={200} duration={5000} autoplay={true} arrows={false} infinite={true} >
       {slideImages.map((slideImage, index)=> (
          <div className="each-slide" key={index}>
          <img src={slideImage.file}   />
          </div>
        ))} 
       
      </Slide>
      <MakesOverlay>

        <Overlay>
         <div>
          <img src={icone4} />
          </div>
          <span>Conseil Graphique</span>
          <p>Une autre dimension du design à votre portée</p>
        </Overlay>
          
         
        <Overlay>

        <div>
          <img src={icone2} />
          </div>
          <span>Communication</span>
          <p>Plonger dans l'univers de la Communication</p> 
        </Overlay> 

        <Overlay>
        <div>
          <img src={icone3} />
          </div>
          <span>Découverte</span>
          <p>D'autre horizons passionnants s'offre à vous</p>

        </Overlay>  

        <Overlay>
          <div>
          <img src={icone1} />
          </div>
          <span>Experience Client</span>
          <p>Vivre une experience uniqueet particulière avec votre clientèle</p>

        </Overlay> 
        
       
      </MakesOverlay>
      
        
      
        <Slogan>
            <span className='slogan'>
                <span className='slogan-bloc'>
                    Nous réinventons <br/>
                <span className='slogan-pop'>Votre</span><br/>
                    Communication
                </span>
              <div className='btn'>
                <StyledButton onClick={handleClickScroll}>Nos réalisations</StyledButton>
                <StyledButton active="true" onClick={handleClickScroll_}>Contactez-nous</StyledButton>
              </div>
            </span>
        </Slogan>
    </BannerContainer>
  )
}

const Overlay = styled.div`
      display: flex;
      height: 230px;
      width: 180px;
      align-items: center;
      color: #351f12;
      justify-content: space-between;
      padding: 30px 10px;
      text-align: center;
      border-radius: 15px;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 2px 10px gray;
      &:hover{
        color: white;
        background-color: #e16200;
        transition: all 200ms;
        transform: scale(0.9);
        transition: transform 200ms;
        transition-delay: 500ms;
        img{
          filter: invert(92%) sepia(99%) saturate(38%) hue-rotate(197deg) brightness(186%) contrast(99%);
        }
      }

      div{
      img{
        height: 40px;
        
      }
    }
      span{
       
        font-weight: bold;
      }

`;
const MakesOverlay = styled.div`
      position: absolute;
      bottom: -5.8px;
      background: center no-repeat url(${base});
      background-size: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      height: 300px;
      background-color: transparent;
      color: black;
      padding-bottom: 50px;
      width: 100%;
      
      svg{
        position: absolute;
        top: 0;
        width: 100%;

      }
      @media (max-width: 1100px){
        display: none;
      }
`;
const BannerContainer = styled.div`
          border-radius: 100% 0% 50% 50% / 0% 0% 100% 100% ;
          position: relative;
          align-items: center;
            img{
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  filter: brightness(100%);
              }
            
`;
const Slogan =  styled.div`
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 5;
    span.slogan-bloc{
      
      color: #351f12;
      font-size: 50px;
      line-height: 5rem;
      text-shadow: 0px 2px 6px inherit;
    .slogan-pop{
        color: white;
        font-size: 76px;
        font-family: Audhistine;
      }
      .btn{
        display: flex;
        justify-content: space-between;
        width: 500px;


      }
    }
    @media (max-width: 1100px) {
      
    }
    @media (max-width: 450px){
      
      span.slogan-bloc{
      
      font-size: 10px;
      line-height: 2rem;
      text-shadow: 0px 2px 6px inherit;
    .slogan-pop{
        color: white;
        font-size: 36px;
        font-family: Audhistine;
      }
      .btn{
        display: flex;
        justify-content: flex-start;
        width: auto;
      }
      }
      }
`;
const StyledButton =  styled.a`
  cursor: pointer;
  display: inline-block;
  color: ${props => props.active === "true" ? "#351f12" : "white"};
  background-color: ${props => props.active === "true" ? "white" : "orange"};
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid transparent;
  margin-left: 10px;
  border-radius: 6px;
  &:hover{
    color: #351f12;
    background-color: white
  }
  @media (max-width: 450px){
    font-size: 8px;
    padding: 1px 3px;
    border-radius: 2px;
  }
  @media (max-width: 1100px){

  }
`;

export default BannerSlider;