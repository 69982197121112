import React, { useState } from 'react'
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import dghub from '../resource/img/02/digital hub.png'
import ebene from '../resource/img/02/ebenezer.png'
import kosmetiks from '../resource/img/02/kosmetiks.png'
import ola from '../resource/img/02/ola.png'
import oasis from '../resource/img/02/oazis.png'
import malek from '../resource/img/02/mayelia.png'
import adec from '../resource/img/02/adec.png'





function Partner() {
      const [ResSlide,setRes] = useState(800)
  return (
      <PartnetContainer idres={ResSlide}>
      <span className='partner'><b>ILs</b> nous ont fait <b>C</b>onfiance</span>
            <StyledCarousel showThumbs={false} interval={2000} infiniteLoop={true} centerSlidePercentage={20} showStatus={false} width={700}  centerMode={true} showArrows={false} autoPlay={true} showIndicators={false}>
            
                        <img src={dghub} />
                        <img src={ebene} />
                        <img src={kosmetiks} />
                        <img src={ola} />
                        <img src={oasis} />
                        <img src={malek} />
                        <img src={adec} />

            </StyledCarousel>
    </PartnetContainer>
  )
}
const PartnetContainer =  styled.div`
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 100px 0;
      .partner{
            font-size: 36px;
            font-weight: 100;
            b{
                  color: orangered;
                  font-size: 75px;
            }
      }
      @media screen and (max-width: 450px){
            
            flex-direction: column;
            .partner{
                  font-size: 25px;
                  b{
                        font-size: 36px;
                  }
            }
      }
`;
const StyledCarousel = styled(Carousel)`
      
      @media screen and (max-width: 450px){
            
            width: 300px;
            overflow: hidden;
      }
      
`;
export default Partner;