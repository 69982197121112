import React, { useRef } from 'react'
import BannerSlider from './BannerSlider';
import ContactX from './ContactX';
import Header from './Header';
import Partner from './Partner';
import Services from './Services';
import Teams from './Teams';
import Template from './Template';
import Who from './Who';

export default function App() {
      const servicesRef = useRef(null);
      const whoRef = useRef(null)
      const makeRef = useRef(null)
      const contactRef =  useRef(null)

  return (
    <div style={{overflow : 'hidden'}}>
      <Header servicesRef={servicesRef} whoRef={whoRef} makeRef={makeRef} contactRef={contactRef} />
      <BannerSlider conRef={contactRef} templateRef={makeRef}/>
      <Who ref={whoRef} />
      <Services ref={servicesRef}  />
      <Template ref={makeRef}/>
      <Teams/>
      <Partner/>
      <ContactX ref={contactRef}/>
    </div>
  )
}
