import React from 'react'
import styled from 'styled-components';
import bg from '../resource/img/02/bgServices.svg'
import account from '../resource/img/02/account.svg'
import lock from '../resource/img/02/lock.svg'
import cpu from '../resource/img/02/cpu.svg'
import bot from '../resource/img/02/bot.svg'
import message from '../resource/img/02/message.svg'
import book from '../resource/img/02/book.svg'
import {forwardRef} from 'react'

const Services = forwardRef((props,ref)=> {
  return (
    <ServicesContainer>
      <div className='bg' ref={ref}>
            <span className='services'>
            <p>Nos Services</p></span>
            <div className='grid'>
                  <div>
                        <img src={account} />
                        <span className='title'>Account</span>
                        <span className='body'>Manage an unlimited number of accounts from one place</span>
                  </div>
                  <div>
                        <img src={lock} />
                        <span className='title'>Roles & Permissions</span>
                        <span className='body'>Full control with flexible user permissions for views and actions</span>
                  </div>
                  <div>
                        <img src={cpu} />
                        <span className='title'>Integration</span>
                        <span className='body'>Connect the tools you already use</span>
                  </div>
                  <div>
                         <img src={bot} />
                        <span className='title'>Chat Bots</span>
                        <span className='body'>AI powered chatbots to improve your business satisfaction</span>
                  </div>
                  <div>
                  <img src={message} />
                        <span className='title'>In-App Messaging</span>
                        <span className='body'>Build a better business with proactive messages</span>
                  </div>
                  <div>
                  <img src={book} />
                        <span className='title'>Knowledge Base</span>
                        <span className='body'>Create and publish answers for customers</span>
                  </div>
            </div>
           
      </div>
    </ServicesContainer>
  )
});

const ServicesContainer = styled.div`
      position: relative;
      height: 120vh;
      background: no-repeat center content-box url(${bg});
      background-size: 100%;
      //border: 1px solid green;
      background-color: rgba(249, 183, 87);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .bg{
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            flex-direction: row;
            //backdrop-filter: contrast(50%);
            height: 100%;
            //border: 2px solid red;
            padding: 20px;
            .grid{
                  display: grid;
                  row-gap: 20px;
                  column-gap: 150px;
                  grid: auto-flow / 1fr 1fr 1fr;
                  color: red;
                  div{
                        
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 16px;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                        backdrop-filter: blur(9.7px);
                        -webkit-backdrop-filter: blur(9.7px);
                        border: 3px solid white;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-around;
                        flex-direction: column;
                        height: 250px;
                        padding: 15px;
                        width: 200px;
                        color: white;
                        
                        img{
                              height: 50px;
                        }
                        .body{
                              font-size: 12px;
                        }
                  }
            }
            .services{
                  position: absolute;
                  top: 0;
                  display: flex;
                  font-weight: bold;
                  align-items: center;
                  font-size:25px;
                  color: white;
                  height: 200px;
                  background-color: rgba(255,255,255,0.5);
                  padding: 20px;
                  width: fit-content;
                  transform:
                  scale(0.8)
                  rotateX(51deg)
                  rotateZ(43deg);
                  transform-style: preserve-3d;
                  border-radius: 32px;
                  box-shadow:
                  1px 1px 0 1px #f9f9fb,
                  -1px 0 28px 0 rgba(34, 33, 81, 0.01),
                  28px 28px 28px 0 rgba(34, 33, 81, 0.25);
                  transition:
                  .4s ease-in-out transform,
                  .4s ease-in-out box-shadow;

                  &:hover {
                  transform:
                        scale(0.8)
                        translate3d(20px, -16px, 0px)
                        //rotateX(51deg)
                        //rotateZ(43deg);
                        rotateX(51deg)
                        rotateZ(43deg);
                  box-shadow:
                        1px 1px 0 1px #f9f9fb,
                        -1px 0 28px 0 rgba(34, 33, 81, 0.01),
                        54px 54px 28px -10px rgba(34, 33, 81, 0.15);
                  }
            }
            @media (max-width: 450px) {
                  transform: scale(0.4);
                  align-items: center;
                  height: fit-content;  
                  border: none;
                  backdrop-filter: none;
                  .services{
                        display: none;
                  }
                  .grid{
                        div{
                              transform: scale(0.8);
                        }
                  }
            }
      }
      @media (max-width: 450px){
            height: 30vh;
            background: rgba(249, 183, 87);

      }
`;
export default Services;