import React from 'react'
import styled from 'styled-components'
import logo from '../resource/img/01/saraiiko.png'
import bg from '../resource/img/01/bulle.jpg'
import loader from '../resource/img/01/loader.png'



function Maintenance() {
  return (
    <Container>
        <Content>
        <img src={bg} />
            <Teaser>
                <img src={logo} />
                <Loader> <span>Bientot disponible</span><img src={loader}  /> </Loader>
            </Teaser>
            <Info>
          <div>Tel : +2250707009017 / +2252720237511</div>
        </Info>
        </Content>
        
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  position: fixed;
`;
const Info =  styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  font-size: 20px;
  color: whitesmoke;
  font-weight: lighter;
`;

const Content = styled.div`
  pointer-events: none;
  user-select: none;
  scroll-behavior: smooth;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  img{
  
    position: absolute;
    filter: blur(15px);
    object-fit: cover;
    
  }
`;
const Teaser = styled.p`
  color: red;
  font-size: 36px;
  flex-direction: column;
  z-index: 2;
  img{
    filter: blur(0px) contrast(10%);
    position: relative;
    height: 200px;
  }
`;

const Loader = styled.span`
    position: relative;
    display: flex;
    font-size: 18px;
    color: white;
    justify-content: center;
    align-items: center;
    img{
      position: absolute;
      height: 20px;
      right: 68px;
      top: 4px;
      animation: load  4s linear infinite;

    }
    @keyframes load {
      0%{
        transform: rotate(0deg);
        
      }
      100%{
        transform: rotate(360deg);
        
      }
    }

`;

export default Maintenance;