import logo from './logo.svg';
import styled from 'styled-components'
import css from './App.css'
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Maintenance from './components/Maintenance'
import reportWebVitals from "./reportWebVitals";
import Header from './components/Header';
import App from './components/App';
import BannerSlider from './components/BannerSlider';
import Who from './components/Who';
import Services from './components/Services';
import Template from './components/Template';
import Partner from './components/Partner';
import Teams from './components/Teams';
import Contact from './components/Contact';
import ContactX from './components/ContactX';
import { useRef } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.title = 'Saraiiko: Agence Créative'
root.render(
  <>
    <App/>
  </>
);

// If you want to start measusring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
