import React from 'react'
import styled from 'styled-components'
import logo from '../resource/img/01/saraiiko.png'

function Contact() {
  return (
    <ContactContainer>
      <div className='mailing'>
            <div className='send'>
                  <input type={'email'} placeholder='Rejoindre la NewsLetter'/> <br/>
                  <input type={'submit'} value='Rejoindre'/>
                  
                  <div className='data-field'>
                        
                  <div className='rs-title'>Nos Réseaux Sociaux</div>
                  <div className='rs'> 

                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        
                  </div> 
                  
                  </div>
            </div>
            <div className='contact'>
                  <img src={logo} />
            </div>
      </div>
      <div className='footer'>

      </div>
    </ContactContainer>
  )
}
const ContactContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 59% 41% 50% 50% / 45% 79% 21% 55% ;
      height: 80vh;
      background-color: aliceblue;
      .mailing{
            display: grid;
            grid-template-columns: 750px 500px;
            grid-template-rows: 400px;
            gap: 50px;
            .send{
                  padding: 20px;
                  background-color: red;
                  text-align: center;
                  border-radius: 21% 79% 40% 60% / 69% 26% 74% 31% ;
                  div{
                        text-align: left;
                        color: white;
                        font-weight: 700;
                        }
                  input[type='email']{
                        padding: 10px;
                        border-radius: 25px;
                        border: none;
                        margin-right: 10px;
                        outline: none;
                        padding-inline-start: 20px;
                        box-shadow: 2px 0px 10px rgba(0,0,0,0.05);
                        
                  }
                  input[type='submit']{
                        margin-top: 15px;
                        margin-left: 13px;
                        padding: 5px 8px;
                        background-color: orange;
                        border: none;
                        color: white;
                        border-radius: 5px;
                        cursor: pointer;
                  }
                  .data-field{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        .rs-title{
                              font-size: 36px;
                        }
                        .rs{
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              width: 100%;
                              div{
                                    border: 1px solid;
                                    height: 100px;
                                    width: 100px;
                                    border-radius: 100px;
                                    margin-top: 20px;
                                    background: rgba(255,255,255,0.2);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                              }
                        }
                  }
            }
            .contact{
                  text-align: center;
                  padding: 20px;
                  border-radius: 42% 58% 44% 56% / 25% 0% 100% 75%;
                  background-color: skyblue;
                  
                  img{
                        height: 250px;
                  }
            }
      }
      @media screen and (max-width: 450px) {
            width: 450px;
            transform: scale(0.3);
      }
`;

export default Contact