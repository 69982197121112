import React from 'react'
import styled from 'styled-components'
import av1 from '../resource/img/02/av1.jpg'
import av2 from '../resource/img/02/av2.jpg'

function Teams() {
  return (
    <TeamsContainer>
      <div className='head'>
            -     NOTRE <span className='light'>EQUIPE</span>     - 
           <div>Jeune Dynamique & Créative</div> 
      </div>
      <div className='body'>
            <div className='person'>
                  <img src={av1} />
                  <span className='name'>NOM</span>
                  <span className='job'>FONCTION</span>
            </div>
            <div className='person'>
                  <img src={av2} />
                  <span className='name'>NOM</span>
                  <span className='job'>FONCTION</span>
            </div>
            <div className='person'>
                  <img src={av1} />
                  <span className='name'>NOM</span>
                  <span className='job'>FONCTION</span>
            </div>
      </div>
    </TeamsContainer>
  )
}
const TeamsContainer = styled.div`
      display: flex;
      //justify-content: space-around;
      background-color: whitesmoke;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      position: relative;
      background-blend-mode: difference;
      border-radius: 44% 56% 36% 64% / 45% 0% 100% 55% ;
      .head{
            font-family: Audhistine;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            transform: rotateZ(-5deg);
            border: 1px solid transparent;
      }
      .body{
            
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 100%;
            .person{
                  backdrop-filter: blur(6px);
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  align-items: center;
                  padding: 20px;
                  border-radius: 15% 85% 40% 60% / 69% 26% 74% 31% ;
                  background-color: orangered;
                  color: white;
                  img{
                        height: 300px;
                        border-radius: 44% 56% 36% 64% / 30% 30% 70% 70% 

                  }
                  .name{
                        font-weight: bold;
                  }
            }
      }
      @media (max-width: 450px){
            .head{
                  font-size: 15px;
            }
            .body{
                  
                  flex-direction: column;
                  transform: scale(0.5);
                 // justify-content: space-between;
            }
            
      }
      
`;
export default Teams;