import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { forwardRef } from 'react';

import card from '../resource/img/01/CARTE 1.jpg'
import card1 from '../resource/img/01/GJGG.jpeg'
import card2 from '../resource/img/01/couvertureguide.jpg'
import card3 from '../resource/img/01/img-adec face.jpg'
import card4 from '../resource/img/01/img-adec face.jpg'
import card5 from '../resource/img/01/rease.jpeg'



const Template = forwardRef((props,ref)=> {
      const [breakpoint,setBreakpoint]= useState(true)
      const width = window.innerWidth

useEffect(() => {
      
      console.log(breakpoint)
      Carousel.defaultProps.showThumbs = breakpoint
}, [])

  return (
    <TemplateContainer >
    
      <div className='grid' ref={ref}>
            <div className='template'>Réalisations</div>
            <StyledCarousel width={500} showThumbs={breakpoint} showStatus={false} centerMode={false}>
                  
                  <img src={card} />

                  <img src={card1} />

                  <img src={card2} />

                  <img src={card3} />

                  <img src={card5} />
                  
            </StyledCarousel>
      </div>
    </TemplateContainer>
  )
})

const TemplateContainer =  styled.div`
      
      display: flex;
      align-items: center;
      height: 100vh;
      justify-content: center;
      padding: 50px;
      background-color: whitesmoke;
      border-radius: 75% 25% 60% 40% / 50% 100% 0% 50% ;
      .grid{
            
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            .template{
                  
                  top: 0;
                  display: flex;
                  font-weight: bold;
                  align-items: center;
                  font-size:25px;
                  color: white;
                  height: 200px;
                  background-color: gray;
                  padding: 20px;
                  width: fit-content;
                  transform:
                  scale(0.8)
                  rotateX(51deg)
                  rotateZ(43deg);
                  transform-style: preserve-3d;
                  border-radius: 32px;
                  box-shadow:
                  1px 1px 0 1px #f9f9fb,
                  -1px 0 28px 0 rgba(34, 33, 81, 0.01),
                  28px 28px 28px 0 rgba(34, 33, 81, 0.25);
                  transition:
                  .4s ease-in-out transform,
                  .4s ease-in-out box-shadow;

                  &:hover {
                  transform:
                        scale(0.8)
                        translate3d(20px, -16px, 0px)
                        //rotateX(51deg)
                        //rotateZ(43deg);
                        rotateX(51deg)
                        rotateZ(43deg);
                  box-shadow:
                        1px 1px 0 1px #f9f9fb,
                        -1px 0 28px 0 rgba(34, 33, 81, 0.01),
                        54px 54px 28px -10px rgba(34, 33, 81, 0.15);
                  }
            }
            @media screen and (max-width: 450px){
                  flex-direction: column;
                  .template{
                        
                        transform: none;
                        border: 5px solid orangered;
                        padding: 10px 20px;
                        font-weight: lighter;
                        margin-bottom: 85px;
                        height: fit-content;
                        background-color: inherit;
                        box-shadow: none;
                        color: black;
                        font-size: 30px;
                  }
            }

      }
      .img{
            display: grid;
            grid-template-columns: 200px 200px 300px;
            grid-auto-rows: 200px;
            gap: 20px;
            
            div{
                  border: 1px solid;
                  &:hover{
                        transform: scale(1.5);
                        transition: 200ms transform;
                        transition-delay: 200ms;
                  }
            }
      }
`;
const StyledCarousel = styled(Carousel)`
      img{
            border-radius: 5px;
            object-fit: contain;
      }
      
      @media screen and (max-width: 450px){
            img{
                  width: 200px;
                  height: 200px;

            }
            
            ${(props)=>{
                  props.autoFocus = true
                  props.thumbWidth = 50
                        }}
      }
`;

export default Template